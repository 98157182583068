import http from "@/utils/http";

export function AdminInfo(params){
    return  http({
        url: "admin/info",
        method: "get",
        param: params
    })
}

export function AdminList(params){
    return http({
        url: "admin/list",
        method: "get",
        param: params
    })
}

export function AdminMenu(params){
    return http({
        url: "admin/menu",
        method: "get",
        params: params
    })
}

export function AdminAdd(params){
    return http({
        url: "admin/add",
        method: "post",
        param: params
    })
}

export function AdminEdit(params){
    return http({
        url: "admin/edit",
        method: "post",
        param: params
    })
}

export function AdminStatus(params){
    return http({
        url: "admin/status",
        method: "post",
        data: params
    })
}

export function AdminDelete(params){
    return http({
        url: "admin/delete",
        method: "post",
        param: params
    })
}