import {createRouter, createWebHashHistory} from 'vue-router'

const routes = [
  {
    path: '/',
    redirect: 'Home'
  },
  {
    path: '/Home',
    name: 'Home',
    component: () => import ('../views/Home.vue'),
    meta: {
      title: '首页'
    }
  }, {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/Login'),
    meta: {
      title: '登录'
    }
  }, {
    path: '/Admin',
    name: 'Admin',
    component: () => import ('../views/Admin.vue'),
    meta: {
      title: '管理员'
    }
  }, {
    path: '/AdminAdd',
    name: 'AdminAdd',
    component: () => import ('../views/AdminAdd.vue'),
    meta: {
      title: '添加管理员'
    }
  }, {
    path: '/AdminEdit',
    name: 'AdminEdit',
    component: () => import ('../views/AdminEdit.vue'),
    meta: {
      title: '管理员编辑'
    }
  },{
    path: '/Banner',
    name: 'Banner',
    component: () => import ('../views/Banner.vue'),
    meta: {
      title: '派对管理'
    }
  }, {
    path: '/BannerAdd',
    name: 'BannerAdd',
    component: () => import ('../views/BannerAdd.vue'),
    meta: {
      title: '添加派对'
    }
  }, {
    path: '/BannerEdit',
    name: 'BannerEdit',
    component: () => import ('../views/BannerEdit.vue'),
    meta: {
      title: '脱单编辑'
    }
  }, {
    path: '/Category',
    name: 'Category',
    component: () => import ('../views/Category.vue'),
    meta: {
      title: '派对管理'
    }
  }, {
    path: '/CategoryAdd',
    name: 'CategoryAdd',
    component: () => import ('../views/CategoryAdd.vue'),
    meta: {
      title: '添加派对'
    }
  }, {
    path: '/CategoryEdit',
    name: 'CategoryEdit',
    component: () => import ('../views/CategoryEdit.vue'),
    meta: {
      title: '脱单编辑'
    }
  }, {
    path: '/Product',
    name: 'Product',
    component: () => import ('../views/Product.vue'),
    meta: {
      title: '派对管理'
    }
  }, {
    path: '/ProductAdd',
    name: 'ProductAdd',
    component: () => import ('../views/ProductAdd.vue'),
    meta: {
      title: '添加派对'
    }
  }, {
    path: '/ProductEdit',
    name: 'ProductEdit',
    component: () => import ('../views/ProductEdit.vue'),
    meta: {
      title: '脱单编辑'
    }
  },{
    path: '/Cate',
    name: 'Cate',
    component: () => import ('../views/Cate.vue'),
    meta: {
      title: '派对管理'
    }
  }, {
    path: '/CateAdd',
    name: 'CateAdd',
    component: () => import ('../views/CateAdd.vue'),
    meta: {
      title: '添加派对'
    }
  }, {
    path: '/CateEdit',
    name: 'CateEdit',
    component: () => import ('../views/CateEdit.vue'),
    meta: {
      title: '脱单编辑'
    }
  },{
    path: '/Recharge',
    name: 'Recharge',
    component: () => import ('../views/Recharge.vue'),
    meta: {
      title: '派对管理'
    }
  }, {
    path: '/RechargeAdd',
    name: 'RechargeAdd',
    component: () => import ('../views/RechargeAdd.vue'),
    meta: {
      title: '添加派对'
    }
  }, {
    path: '/RechargeEdit',
    name: 'RechargeEdit',
    component: () => import ('../views/RechargeEdit.vue'),
    meta: {
      title: '脱单编辑'
    }
  },{
    path: '/Coupon',
    name: 'Coupon',
    component: () => import ('../views/Coupon.vue'),
    meta: {
      title: '派对管理'
    }
  },{
    path: '/CouponAdd',
    name: 'CouponAdd',
    component: () => import ('../views/CouponAdd.vue'),
    meta: {
      title: '添加派对'
    }
  }, {
    path: '/CouponEdit',
    name: 'CouponEdit',
    component: () => import ('../views/CouponEdit.vue'),
    meta: {
      title: '脱单编辑'
    }
  },{
    path: '/Film',
    name: 'Film',
    component: () => import ('../views/Film.vue'),
    meta: {
      title: '派对管理'
    }
  },{
    path: '/FilmAdd',
    name: 'FilmAdd',
    component: () => import ('../views/FilmAdd.vue'),
    meta: {
      title: '添加派对'
    }
  }, {
    path: '/FilmEdit',
    name: 'FilmEdit',
    component: () => import ('../views/FilmEdit.vue'),
    meta: {
      title: '脱单编辑'
    }
  }, {
    path: '/File',
    name: 'File',
    component: () => import ('../views/File.vue'),
    meta: {
      title: '文件管理'
    }
  }, {
    path: '/FileCash',
    name: 'FileCash',
    component: () => import ('../views/FileCash.vue'),
    meta: {
      title: '垃圾桶'
    }
  },
  {
    path: '/Member',
    name: 'Member',
    component: () => import ('../views/Member.vue'),
    meta: {
      title: '用户列表'
    }
  },
  {
    path: '/MemberEdit',
    name: 'MemberEdit',
    component: () => import ('../views/MemberEdit.vue'),
    meta: {
      title: '用户编辑'
    }
  },
  {
    path: '/Role',
    name: 'Role',
    component: () => import ('../views/Role.vue'),
    meta: {
      title: '角色管理'
    }
  },
  {
    path: '/RoleAdd',
    name: 'RoleAdd',
    component: () => import ('../views/RoleAdd.vue'),
    meta: {
      title: '添加角色'
    }
  },
  {
    path: '/RoleEdit',
    name: 'RoleEdit',
    component: () => import ('../views/RoleEdit.vue'),
    meta: {
      title: '角色编辑'
    }
  },
  {
    path: '/Help',
    name: 'Help',
    component: () => import ('../views/Help.vue'),
    meta: {
      title: '角色管理'
    }
  },
  {
    path: '/HelpAdd',
    name: 'HelpAdd',
    component: () => import ('../views/HelpAdd.vue'),
    meta: {
      title: '添加角色'
    }
  },
  {
    path: '/HelpEdit',
    name: 'HelpEdit',
    component: () => import ('../views/HelpEdit.vue'),
    meta: {
      title: '角色编辑'
    }
  },
  {
    path: '/System',
    name: 'System',
    component: () => import ('../views/System.vue'),
    meta: {
      title: '系统设置'
    }
  },
  {
    path: '/Report',
    name: 'Report',
    component: () => import ('../views/Report.vue'),
    meta: {
      title: '投诉列表',
      keepAlive: true
    }
  },
  {
    path: '/ReportCategory',
    name: 'ReportCategory',
    component: () => import ('../views/ReportCategory.vue'),
    meta: {
      title: '投诉列表',
      keepAlive: true
    }
  },
  {
    path: '/ReportCategoryAdd',
    name: 'ReportCategoryAdd',
    component: () => import ('../views/ReportCategoryAdd.vue'),
    meta: {
      title: '投诉列表',
      keepAlive: true
    }
  },
  {
    path: '/ReportCategoryEdit',
    name: 'ReportCategoryEdit',
    component: () => import ('../views/ReportCategoryEdit.vue'),
    meta: {
      title: '投诉列表',
      keepAlive: true
    }
  },
  {
    path: '/Static',
    name: 'Static',
    component: () => import ('../views/Static.vue'),
    meta: {
      title: '投诉列表',
      keepAlive: true
    }
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
