// by Waymon 2021 9 1
// export const apiUrl = 'http://127.0.0.1:8081/admin/v1/'
export const apiUrl = 'https://coupon.sainiao.net/admin/v1/'

export const uploadUrl = apiUrl + 'upload'
export function getStorage(key) {
  const value = window.localStorage.getItem(key)
  try {
    return JSON.parse(window.localStorage.getItem(key))
  } catch (error) {
    return value
  }
}

export function getSession(key) {
   const value = window.sessionStorage.getItem(key)
  try {
     return JSON.parse(window.sessionStorage.getItem(key))
  } catch (error) {
     return value
  }
}

export function setStorage(key, value) {
  window.localStorage.setItem(key, JSON.stringify(value))
}

export function setSession(key, value) {
  window.sessionStorage.setItem(key, JSON.stringify(value))
}

export function removeStorage(key) {
  window.localStorage.removeItem(key)
}


export function removeSession(key) {
  window.sessionStorage.removeItem(key)
}